var imagens = document.querySelectorAll('.eyeIcon');
imagens.forEach(function (imagem) {
    imagem.addEventListener('click', function () {
        var imagemAtual = this.src;
        if (imagemAtual.endsWith('eyeIcon.svg')) {
            this.src = 'https://development-na01-interplayers.demandware.net/on/demandware.static/-/Library-Sites-RefArchSharedLibrary/default/v54ca49807aa8639ccdfbaf097a17e98376139e68/eyeIconOff.svg';
            this.alt = 'Esconder senha'; 
            this.class = 'eyeIconOff';
            this.classList.remove('eyeIcon');
            this.classList.add('eyeIconOff');
        } else {
            this.src = 'https://development-na01-interplayers.demandware.net/on/demandware.static/-/Library-Sites-RefArchSharedLibrary/default/v54ca49807aa8639ccdfbaf097a17e98376139e68/eyeIcon.svg';
            this.alt = 'Mostrar senha';
            this.class = 'eyeIcon';
            this.classList.remove('eyeIconOff');
            this.classList.add('eyeIcon');
        }
    });
});

const togglePassword = document.querySelector('#togglePassword');
const loginPassword = document.querySelector('#login-form-password');
togglePassword.addEventListener('click', function (e) {
    const type = loginPassword.getAttribute('type') === 'password' ? 'text' : 'password';
    loginPassword.setAttribute('type', type);
    // this.classList.toggle('bi-eye');
});

const togglePasswordRegister = document.querySelector('#togglePasswordRegister');
const togglePasswordRegisterConfirm = document.querySelector("#togglePasswordRegisterConfirm");
const password = document.querySelector('#registration-form-password');
const passwordConfirm = document.querySelector("#registration-form-password-confirm")
togglePasswordRegister.addEventListener('click', function (e) {
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
});
togglePasswordRegisterConfirm.addEventListener('click', function (e){
    const typeConfirm = passwordConfirm.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordConfirm.setAttribute('type', typeConfirm);
})


const toggleCurrentPassword = document.querySelector('#toggleCurrentPassword');
const togglePasswordEdit = document.querySelector('#togglePasswordEdit');
const togglePasswordEditConfirm = document.querySelector('#togglePasswordEditConfirm');

const currentPassword = document.querySelector('#password');
const newpassword = document.querySelector('#newpassword');
const newpasswordconfirm = document.querySelector('#newpasswordconfirm');

toggleCurrentPassword.addEventListener('click', function (e) {
    const type = currentPassword.getAttribute('type') === 'password' ? 'text' : 'password';
    currentPassword.setAttribute('type', type);
});

togglePasswordEdit.addEventListener('click', function (e) {
    const type = newpassword.getAttribute('type') === 'password' ? 'text' : 'password';
    newpassword.setAttribute('type', type);
});

togglePasswordEditConfirm.addEventListener('click', function (e) {
    const type = newpasswordconfirm.getAttribute('type') === 'password' ? 'text' : 'password';
    newpasswordconfirm.setAttribute('type', type);
});
